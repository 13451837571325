import { ErrorMessage, Formik } from "formik";
import ReturnIcon from "../../assets/images/return.svg";
import Alert from "../../assets/images/danger.svg";
import Header from "../../components/header";
import { Form } from "react-bootstrap";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Services from "../../services/global.service";
import { toast } from "react-toastify";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe("pk_live_51MSLv7AZZK9BGXONqPx5hdgX3eXRbsm9JrBwLFFp9ToZF9ZLa4zsPeT0dgU5lj47XhVTpWtncY7takFgs43eVtDO00DNRdxL1L");
const BankAccount = () => {
  const [user, setUser] = useState(null);
  const [docs, setDocs] = useState({
    justificatif: [],
    permis: [],
    carteGrise: [],
    assurance: [],
  });
  const navigate = useNavigate();
  const validationSchema = Yup.object({
    titulaire: Yup.string().required("Titulaire est obligatoire"),
    iban: Yup.string()
      // .matches(
      //   /^FR\d{2}[A-Z0-9]{23}$/,
      //   "IBAN doit commencer par FR suivi de 2 chiffres et de 23 caractères alphanumériques"
      // )
      // .length(27, "IBAN doit contenir exactement 27 caractères pour les comptes en France")
      .required("IBAN est obligatoire"),


    bic: Yup.string()
      .test(
        'bic-length',
        'BIC doit contenir exactement 8 ou 11 caractères',
        (value) => value && (value.length === 8 || value.length === 11)
      )
      .required("BIC est obligatoire"),
  });

  // Handle form submission
  const handleSubmit = async (values, { setSubmitting }) => {
    // console.log("Form data:", values);

    console.log({ user })
    const stripe = await stripePromise;
    // Parse the date (works with ISO strings like "1990-05-15" or Date objects)
    const birthDate = new Date(user.date_naissance);

    // const result = await stripe.createToken("account", {
    //   individual: {
    //     first_name: user.first_name,
    //     last_name: user.last_name,
    //     email: user.email,
    //     phone: `+33${user.telephone}`,
    //     dob: {
    //       day: birthDate.getDate(),
    //       month: birthDate.getMonth() + 1,
    //       year: birthDate.getFullYear(),
    //     },
    //     address: {
    //       line1: user.address,
    //       city: user.city,
    //       postal_code: user.postal_code,
    //       country: 'FR',
    //     },
    //   },
    //   tos_shown_and_accepted: true,
    // });
      // Simulate async operation
      await Services.create({ ...values }, "stripe/create-account")
        .then((res) => {
          // console.log(res);
          toast.success("Votre opération a réussi !", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            // theme: 'light', // Choose theme (light/dark)
            className: "custom-toast",
          });
        })
        .catch((err) => {
          // console.log(err);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            // theme: 'light', // Choose theme (light/dark)
            className: "custom-toast-error",
          });
        });
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  };
  const getDocs = () => {
    const idUser = localStorage.getItem("userData");
    Services.getOne(idUser, "users")
      .then((res) => {
        setUser(res?.data?.data);
        // console.log("documents", res?.data?.data);
        const documents = res?.data?.data?.documents || [];

        // Initialize the new docs state
        const updatedDocs = {
          justificatif: [],
          permis: [],
          carteGrise: [],
          assurance: [],
        };

        // Map documents to the corresponding doc types
        documents.forEach((doc) => {
          // console.log("doc", doc);
          switch (doc.name) {
            case "piece_identite":
              updatedDocs.justificatif = [...updatedDocs.justificatif, doc];
              break;
            case "permis_de_conduire":
              updatedDocs.permis = [...updatedDocs.permis, doc];
              break;
            case "carte_grise":
              updatedDocs.carteGrise = [...updatedDocs.carteGrise, doc];
              break;
            case "attestation_assurance":
              updatedDocs.assurance = [...updatedDocs.assurance, doc];
              break;
            default:
              break;
          }
        });
        // console.log("updatedDocs", updatedDocs);
        // Set the updated documents state
        setDocs(updatedDocs);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const initialValues = {
    titulaire: user?.titulaire_compte || "",
    iban: user?.iban || "",
    bic: user?.bic || "",
  };
  useEffect(() => {
    getDocs();
  }, []);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Page compte bancaires </title>
          <meta name="description" content="page pour renseigner l iban " />
        </Helmet>
      </HelmetProvider>

      <div className="desktop">
        <Header connected={true} />
      </div>

      <div className="bg-grey">
        <div className="bg-atchoum-profil">
          <div className="container-fluid-lg">
            <h1 className="text-white p-16 d-flex align-items-center">
              <button onClick={() => navigate(-1)} className="cta">
                <img src={ReturnIcon} className="mr-8" alt="return icon" />
              </button>
              Mes coordonnées bancaires
            </h1>
          </div>
        </div>
        <div className=" mt-24 col-lg-6 mx-auto">
          {user && (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                touched,
                values,
                handleSubmit,
                handleChange,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} className="">
                  <div className="px-16">
                    <div className=" ">
                      {/* titulaire Field */}
                      <div className="floating-input-container">
                        <input
                          type="text"
                          name="titulaire"
                          onChange={handleChange}
                          value={values.titulaire}
                          className={`floating-input ${touched.titulaire && errors.titulaire
                            ? "is-invalid"
                            : ""
                            }`}
                          id="titulaire"
                          placeholder=" " // important for floating effect
                        />
                        <label htmlFor="titulaire" className="floating-label">
                          Titulaire du compte
                        </label>
                      </div>
                    </div>
                    <ErrorMessage name="titulaire">
                      {(msg) => (
                        <div className="error-message">
                          <img src={Alert} alt="Alert" />
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                    {/* <ErrorMessage
                  name="titulaire"
                  component="div"
                  className="invalid-feedback"
                /> */}
                    <div className="mt-8">
                      {/* iban Field */}
                      <div className="floating-input-container">
                        <input
                          type="text"
                          value={values.iban}
                          name="iban"
                          onChange={handleChange}
                          className={`floating-input ${touched.iban && errors.iban ? "is-invalid" : ""
                            }`}
                          id="iban"
                          placeholder=" " // important for floating effect
                        />
                        <label htmlFor="iban" className="floating-label">
                          IBAN
                        </label>
                      </div>
                    </div>
                    {/* <ErrorMessage
                  name="iban"
                  component="div"
                  className="invalid-feedback"
                /> */}
                    <ErrorMessage name="iban">
                      {(msg) => (
                        <div className="error-message">
                          <img src={Alert} alt="Alert" />
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                    <div className="mt-8">
                      {/* BIC Field */}
                      <div className="floating-input-container">
                        <input
                          type="text"
                          name="bic"
                          value={values.bic}
                          onChange={handleChange}
                          className={`floating-input ${touched.bic && errors.bic ? "is-invalid" : ""
                            }`}
                          id="bic"
                          placeholder=" " // important for floating effect
                        />
                        <label htmlFor="bic" className="floating-label">
                          BIC
                        </label>
                      </div>
                    </div>
                    {/* <ErrorMessage
                  name="iban"
                  component="div"
                  className="invalid-feedback"
                /> */}
                    <ErrorMessage name="bic">
                      {(msg) => (
                        <div className="error-message">
                          <img src={Alert} alt="Alert" />
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div className="bottom-white-block">
                    {!docs.justificatif[0]?.updated_at ? (
                      <p className="error-message text-start">
                        Merci de renseigner une carte d'identité
                      </p>
                    ) : (
                      ""
                    )}
                    <button
                      type="submit"
                      className="btn-primary-s mt-8 p-16 w-sm-100"
                      disabled={
                        !docs.justificatif[0]?.updated_at || isSubmitting
                      }
                    >
                      Enregistrer
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </>
  );
};
export default BankAccount;
